import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { handleLogin, isLoggedIn } from "../services/authService";
import "../assets/style/globalStyle.scss";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (isLoggedIn()) {
      navigate(`/app/profile`);
    }
  }, []);

  return (
    <section className="hero has-background-white-ter is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-3-desktop is-6-tablet is-12-mobile">
              <div className="box" style={{ height: "300px" }}>
                <h1
                  className="has-text-centered is-size-2"
                  style={{ marginBottom: "14px" }}
                >
                  Log in
                </h1>
                <div className="field">
                  <label>
                    E-mail
                    <input
                      className="input"
                      type="text"
                      name="email"
                      placeholder="Ton adresse mail"
                      style={{ marginBottom: "14px" }}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </label>
                </div>
                <div className="field">
                  <label>Mot de passe</label>
                  <div
                    className="control is-flex"
                    style={{
                      alignItems: "center",
                      border: "1px solid #dbdbdb",
                      borderRadius: "5px",
                      padding: "5px",
                    }}
                  >
                    <input
                      className="input"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Ton mot de passe"
                      style={{ flexGrow: 1, border: "none", boxShadow: "none" }}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="icon is-small"
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? "👁‍🗨" : "👁"}
                    </span>
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <button
                      className="button"
                      onClick={() => handleLogin({ email, password })}
                    >
                      Se connecter
                    </button>
                  </div>
                  <div className="column">
                    <button className="button" onClick={() => navigate(`/`)}>
                      Retour
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
